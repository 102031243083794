.wholeContent {
  display: flex;
  padding: 20px 40px;
  background-color: #FAFAFA;
  flex-grow: 1;
  max-height: calc(100vh - 60px);
  box-sizing: border-box;
  overflow: hidden;
  gap: 20px;
}

.rhs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  position: relative;
}

.toastMessages {
  bottom: 20px;
}

@media (max-width: 1000px) {
  .wholeContent {
    max-height: fit-content;
    padding-left: 10px;
    padding-right: 10px;
  }
}