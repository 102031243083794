.contentContainer {
  display: flex;
  flex-grow: 1;
  gap: 50px;
}

.titleContainer {
  display: flex;
  gap: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.left {
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 25px;
  border-right: 1px solid #eeeeee;
}


.csvContainer {
  margin-left: auto;
  align-self: center;
}

.right {
  flex-grow: 1;
  flex-basis: 0;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 100px;
}

.modalContent {
  max-width: 600px;
  padding: 20px 0px;
}

.positionsContainer {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}
