.top-bar {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  height: 60px;
  padding: 10px 20px;
  box-sizing: border-box;
  user-select: none;
  gap: 20px;
}

.handle {
  align-self: center;
  fill: #003476;
  cursor: pointer;
  margin-left: 10px;
}

.logo {
  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 1em;
  color: white;
  cursor: pointer;
}

.logo img {
  height: 100%;
}

.top-bar .title {
  width: calc(100% - 2 * 135px - 50px); /* 100% - 2 * logo-width - 50 (gap) */
  position: absolute;

  left: 50%;
  transform: translateX(-50%);

  font-size: 1rem;
  text-align: center;
}

.options {
  display: flex;
}

.option:first-child {
  border-top: 1px solid #dedede;
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 20px;
  padding: 10px 20px;
  border-bottom: 1px solid #dedede;

  user-select: none;
  cursor: pointer;
}

.option .icon {
  fill: #003476;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
}

.option .text {
  font-size: 1.25em;
}

.option.selected {
  background-color: #003476;
}

.option.selected .text {
  color: #ffffff;
}

.option.selected .icon {
  fill: #ffffff;
}

@media (max-width: 1000px) {
  .options {
    position: absolute;
    width: 90%;
    left: 0px;
    overflow: auto;
    top: 100%;
    flex-direction: column;
    background-color: white;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 10px;
  }
  .top-bar {
    height: 50px;
    padding: 5px;
  }

  .logo {
    flex-grow: 1;
    font-size: 0.8em;
  }

  .top-bar .title {
    display: none;
  }
}

.hidden {
  visibility: hidden;
}