.sidebar {
  width: 320px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 5px;
}

.userInfo {
  margin: 40px 0px;
}

.option:first-child {
  border-top: 1px solid #dedede;
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 20px;
  padding: 10px 20px;
  border-bottom: 1px solid #dedede;

  user-select: none;
  cursor: pointer;
}

.option .icon {
  fill: #003476;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
}

.option .text {
  font-size: 1.25em;
}

.option.selected {
  background-color: #003476;
}

.option.selected .text {
  color: #ffffff;
}

.option.selected .icon {
  fill: #ffffff;
}

@media screen and (max-width: 1000px) {
  .sidebar {
    display: none;
  }
}
