.container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  min-width: 600px;
  min-height: 200px;
  max-width: 90vw;
  max-height: 90vh;
  background-color: white;
  z-index: 301;
  position: fixed;

  border-radius: 8px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));

  display: flex;
  flex-direction: column;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
  position: fixed;
}

.content {
  padding-left: 25px;
  padding-right: 25px;
  flex-grow: 1;
  box-sizing: border-box;
  font-size: 1rem;
  overflow: auto;
  box-sizing: border-box;
}

.header {
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.footer {
  width: 100%;
  flex-grow: 0;
  border-top: 1px solid #dedede;
  padding: 15px 25px;
  box-sizing: border-box;
  
  display: flex;
  gap: 10px;
}

/************************************************************************************/

.simpleHeader {
  display: flex;
  padding: 20px 25px;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  gap: 20px;
}

.close {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  fill: #888888;
  cursor: pointer;
  flex-shrink: 0;
}

.close:hover{
  fill: #444444;
}

@media (max-width: 1000px) {
  .modal {
    width: 90vw;
    min-width: unset;
    min-height: unset;
  }
}

@media (max-width: 400px) {
  .modal {
    width: 90vw;
    height: 98vh;
    min-width: unset;
    min-height: unset;
    max-height: unset;
  }

  .footer {
    flex-direction: column;
  }
}