.wholeContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-top: 120px;
  gap: 40px;
}

.currentMessage {
  padding: 15px 0px;
  border-bottom: 2px solid #076de3;
  border-top: 2px solid #076de3;
  box-sizing: border-box;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px -1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1.125em;
  box-sizing: border-box;
}

/**********************************************************************/

.attentionContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.attention {
  color: #dd403a;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  color: #777777;
}

.commentTitle {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.comment {
  padding-left: calc(1rem);
  border: 0;
  border-left: 5px solid #7fabdd;
  background-color: #f7f7f7;
  padding-top: 8px;
  font-size: 1rem;
  padding-bottom: 8px;
}

.comment::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.comment {
  min-height: 100px;
  resize: none;
  width: min(500px, 100%);
  cursor: default;
}

.comment:focus {
  outline: 0;
}

/**********************************************************************/

.stati {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statusContainer {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.statusContainer + .statusContainer {
  padding-top: 20px;
  border-top: 1px solid #eeeeee;
  box-sizing: border-box;
}

.statusContainer .left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.statusContainer .left svg {
  width: 20px;
  height: 20px;
}

.statusContainer .right {
  display: flex;
  flex-grow: 1;
}

.statusContainer .check {
  fill: #4cb71a;
}

.statusContainer .dash {
  fill: #777777;
}

.time {
  font-style: italic;
  color: #777777;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 1000px) {
  .statusContainer {
    flex-direction: column;
  }
}
