.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  gap: 20px;
}

.fileIcon {
  flex-shrink: 0;
}

.fileIcon svg {
  width: 24px;
  height: 24px;
}

.fileName {
  display: inline-block;
  word-break: break-word;
  flex-grow: 1;
  flex-shrink: 1;
}

.download {
  fill: #003476;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}