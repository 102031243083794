.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #eeeeee;
  margin-bottom: 40px;
}

.actions {
  width: 100%;
  display: flex;
  gap: 15px;
  padding: 5px;
  justify-content: center;
}

.action {
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  padding: 8px 8px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;

  background-color: #52CF52;
}

.action:hover {
  background-color: #34BC34;
}

.actionIcon {
  width: 16px;
  height: 16px;
}

.edit {
  background-color: #52CF52;
}

.edit:hover {
  background-color: #34BC34;
}

.delete {
  background-color: #FF5252 !important;
}

.delete:hover {
  background-color: #EB0000 !important;
}

.formContainer.createUser {
  width: min(700px, 80vw);
  padding: 50px 30px;
}

.formContainer.createCarrier {
  width: min(500px, 80vw);
  padding: 50px 30px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  gap: 30px;
  box-sizing: border-box;
}

.tabContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tabContent {
  width: min(500px, 80vw);
  height: 450px;
  margin: auto;
}

.cosmetic {
  width: 100px;
  height: 100px;
  align-self: center;
  fill: #dddddd;
}

button.createUser {
  margin: auto;
}

.createUserWrapper {
  border: 1px solid #e5e7eb;
  border-top: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 15px;
}