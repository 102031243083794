.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  gap: 8px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: auto;
  position: relative;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.speciality {
  display: flex;
  align-items: center;
  gap: 8px;
}
.speciality svg {
  flex-shrink: 0;
  fill: #777777;
}
.addressInfo {
}

.contactInfo {
}
