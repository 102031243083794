.expandable {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}

.expandable .title {
  font-size: 1.375rem;
  display: flex;
  flex-grow: 1;
}

.expandable .arrow {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  fill: #777777;
  flex-shrink: 0;
}

.content {
  padding: 20px;
}