.array-input .panel {
    margin-bottom : 1em;
}

.array-input .panel-body {
    background-color: rgba(0, 0, 0, 0.1);
}

.array-input button {
    margin-right: 0.5em;
}
