.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.5em;
  border-radius: 50%;
  width: 100%;
  height: 100%;

  color: white;
  background-color: #8d6e63;
}

.iconContainerMedium {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.iconContainerLarge {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.profileContainer {
  position: relative;
  z-index: 3;
  margin-left: auto;
}

.invisible {
  display: none;
}

.profilePanel {
  position: absolute;
  margin-top: 20px;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  box-sizing: border-box;

  background-color: white;

  border-radius: 5px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));

  gap: 20px;
  user-select: none;
}

.infoRow {
  margin-bottom: 5px;
  white-space: nowrap;
}

.email {
  color: rgba(0, 0, 0, 0.81);
  font-size: 0.875em;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
