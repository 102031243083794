.announcement {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.date {
  font-size: 1em;
  color: #5A5A5A;
}

.title {
  font-size: 1.25em;
  color: #003476;
  font-weight: bold;
}

.desc {
  font-size: 1.125em;
  color: #2F2F2F;
}