.content {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.files {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file {
  display: flex;
  align-items: center;
  width: min(100%, 500px);
  gap: 20px;
  min-height: 64px;

  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #ffffff;
}

.file>svg {
  width: 24px;
  height: 24px;
}

.progressContainer {
  margin-left: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  padding: 10px;
  font-size: 0.75rem;
}

.loader {
  position: absolute;

  border-radius: 50%;

  box-sizing: border-box;
  animation: spin 2s linear infinite;

  /* circle size */
  width: 2.5rem;
  height: 2.5rem;

  /* spinning part thickness*/
  border: 3px solid #eeeeee;
  border-top: 3px solid #599bff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.complete {
  fill: #3DBE61;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;
}