.description {
  margin-top: 20px;
  font-size: 1.125rem;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 5px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  display: flex;
  gap: 40px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
}