.container {
  display: flex;
  flex-grow: 1;
  gap: 50px;
}

.left {
  width: 75%;
  padding-right: 50px;
  border-right: 1px solid #eeeeee;
}

.backButton {
  display: flex;
  gap: 40px;
}

.right {
  width: 25%;
  min-width: 400px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 100px;
}

.modalContent{
  max-width: 600px;
  padding: 20px 0px;
}