.wholePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  
  width: min(480px, 90vw);
  box-sizing: border-box;
  
  padding: 40px;
  gap: 20px;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px -1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.container button {
  margin-top: 10px;
}

.forgotPassword {
  margin-top: -10px;
}

.title {
  font-size: 1.5em;
  font-weight: 500;
}

.loginWith {
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.loginWith:hover{
  background-color: #0D6EFD;
  color: white;
}

.icon {
  position: absolute;
  left: 20px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.or {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 20px;
}

.orLine {
  height: 1px;
  background-color: #B4B4B4;
  display: flex;
  flex-grow: 1;
}

.recaptcha {
  margin-top: 10px;
}

.noAccountContainer {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (max-width: 400px) {
  .recaptcha {
    margin: auto;
  }

  .noAccountContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon {
    position:relative;
    left: auto;
  }
}