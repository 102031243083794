.button {
  background-color: #0D6EFD;
  color: #ffffff;
  border-radius: 5px !important;
  cursor: pointer;

  min-width: 120px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-size: 1rem;


  position: relative;

  padding-left: 3rem;
  padding-right: 3rem;
}

.button.loading {
  opacity: 0.5;
  cursor: default;
}

.button.disabled {
  opacity: 0.5;
  cursor: default;
}

.button:not(.loading):not(.disabled):hover{
  background-color: #0f62dd;
}

.button:not(.loading) .loader {
  display: none;
}

.button.loading .loader {
  display: block;
  border: 4px solid rgba(125,125,125,0.25);
  border-radius: 50%;
  border-top: 4px solid #f3f3f3;
  width: 1.25rem;
  height: 1.25rem;
  box-sizing: border-box;
  position: absolute;
  animation: spin 2s linear infinite;

  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% { transform: translateY(-50%) rotate(0deg); }
  100% { transform: translateY(-50%) rotate(360deg); }
}