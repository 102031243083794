.panel {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: auto;
}

.headerBar {
  background-color: #003476;
  border-top-left-radius: 5px;
  flex-grow: 0;
  font-size: 1.25em;
  color: white;
  padding: 10px 40px;

  position: sticky;
  top: 0;
  z-index: 2;
}

.content:not(:empty) {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.footerBar {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: #ffffff;
  border-top: 1px solid #dedede;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  flex-grow: 0;
  padding: 10px 40px;

  position: sticky;
  bottom: 0;
  z-index: 2;
}

.content:not(:empty) + .loaderContainer {
  display: none;
}

.content:empty + .loaderContainer + .footerBar {
  display: none;
}

.loaderContainer {
  flex-grow: 1;
  display: flex;
}

@media (max-width: 1000px) {
  .content {
    max-height: fit-content;
    padding: 20px 20px !important;
  }

  .footerBar {
    justify-content: center;
    flex-direction: column;
  }
}