.wholePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  
  width: 700px;
  box-sizing: border-box;
  
  padding: 40px;
  gap: 40px;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px -1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
}