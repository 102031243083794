@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-BoldItalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Thin-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-ThinItalic-webfont.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Light-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-LightItalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-MediumItalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}