.portal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoContainer {
  background-color: #003476;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  gap: 50px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  width: min(1100px, 80vw);
  box-sizing: border-box;
}

.registerOrLogin {
  display: flex;
  gap: 40px;
}

.orLogin {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  font-weight: lighter;
  font-size: 1rem;
}

.register {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.info h2 {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 500;

  margin: 0;
}

.registerBtn {
  display: flex;
  background-color: #52cf52;
  width: min(300px, 80vw);
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
}

.registerBtn:hover {
  background-color: #34bc34;
}

.loginBtn {
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.15);
}

.loginBtn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.login {
  display: flex;
  align-items: center;
  margin: auto;
  color: #ffffff;
  gap: 20px;
}

.blue {
  background-color: #003476;
}

.info .title {
  font-size: 2rem;
  color: #ffffff;
}

.info h1 {
  font-size: 2rem;
  color: #ffffff;
  margin: 0;

  text-align: justify;
  text-justify: inter-word;
  display: flex;
  gap: 100px;
}

.logo {
  padding: 10px;
  background-color: white;
  width: fit-content;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.logo img {
  width: 200px;
}

.info .subtitle {
  color: #ffffff;
  line-height: 2rem;
  min-height: 4rem;
}

.title {
  font-size: 1.5em;
  width: 100%;
  font-weight: bold;
}

.subtitle {
  font-size: 1.25em;
  width: 100%;
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.cards {
  display: flex;
  gap: 40px;
}

.announcementsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 50px 0px;
  width: min(1100px, 80vw);
  box-sizing: border-box;
}

.announcementsContent {
  display: flex;
  width: 100%;
  gap: 100px;
}

.announcements {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-grow: 1;
}

.filters {
  display: flex;
  flex-direction: column;
  width: 30%;
  flex-shrink: 0;
}

.area {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  font-size: 1.125rem;
  cursor: pointer;
}

.area:not(.selected):hover {
  background-color: #eeeeee;
  border-radius: 5px;
}

.area.selected {
  background-color: #003476;
  color: #ffffff;
  border-radius: 5px;
}

.tabs {
  display: flex;
  gap: 20px;
}

.tab {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0;
  font-size: 1rem;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
}

.tab.selected {
  border-bottom: 2px solid #eeeeee;
}

.warning {
  fill: #ffc107;
  width: 40px;
  height: 40px;
  margin: auto;
}

.verifyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;
}

.resendEmail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #eeeeee;
}

.emptyAnnouncements {
  padding: 20px;
}

/* Styles for screens smaller than 1000px */
@media only screen and (max-width: 1000px) {
  .info h1 {
    text-align: center;
  }

  .infoContainer {
    padding: 50px 0px;
    gap: 30px;
  }

  .info {
    width: 90%;
  }

  .cards {
    flex-wrap: wrap;
  }
  .registerOrLogin {
    flex-direction: column;
    gap: 20px;
  }

  .orLogin {
    margin: auto;
  }
  .register {
    gap: 30px;
  }

  .registerBtn {
    margin: auto;
  }

  .announcementsContainer {
    flex-direction: column;
    gap: 30px;
    padding: 30px 0px;
    width: 90vw;
  }

  .announcements {
    width: 100%;
  }

  .filters {
    width: 100%;
  }

  .announcementsContent {
    flex-direction: column;
    gap: 30px;
  }

  .tabs {
    flex-direction: column;
    gap: 20px;
  }

  .tab {
    min-width: auto;
    width: 100%;
    text-align: center;
  }

  .warning {
    width: 30px;
    height: 30px;
  }
}

.selectContainer {
  width: 100%;
  background-color: blue;
}

.selectControl {
  width: 100%;
}
