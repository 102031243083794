.footer {
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d1d1d1;
  padding: 40px 0px;
  background-color: #ffffff;
  padding: 40px 20px;
  gap: 5vw;
}

.carriers {
  display: grid;
  grid-template-columns: auto auto;
  flex-grow: 1;
  align-items: center;
  
  row-gap: 10px;
}

.contactCarriers {
  display: grid;
  grid-template-columns: auto auto;
  flex-grow: 1;
  align-items: center;
  
  row-gap: 20px;
  column-gap: 50px;
}

.carriersTitle {
  grid-column-start: 1;
  grid-column-end: 3;
  padding-bottom: 20px;
  margin-bottom: 20px;

  color: #003476;
  font-size: 1.25rem;
  text-align: center;

  border-bottom: 1px solid #eeeeee;
}

.link {
  margin: auto;
  width: 60%;
}

.projectLogo {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: auto;
  width: 50%;
  padding: 20px;
}

.logo {
  width: 100%;
}

.footerΙtem {
  flex-grow: 1;
  flex-basis: 0;
}

.contactCarrier {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 5px;
  gap: 2vw;
}

.contactItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  flex-grow: 1;
}

.contactCarrier img {
  max-height: 50px;
  width: auto;
  margin: auto;
}

.contactLinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contactInfoButton {
  border: 0;
  background-color: transparent;
}

.modalContent {
  padding-top: 20px;
  padding-bottom: 20px;
}

.logoEoppep {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: auto;
  width: 30%;
}

@media only screen and (max-width: 1000px) and (min-width: 400px){
  .footer {
    flex-direction: column;
  }
}

@media only screen and (max-width: 650px) {
  .footer {
    flex-direction: column;
    gap: 20px;
  }

  .link {
    min-width: 200px;
    width: 50%;
    margin: auto;
  }

  .carriers {
    display: flex;
    flex-direction: column;
  }

  .contactCarriers {
    display: flex;
    flex-direction: column;
  }

  .contactInfoButton {
    width: 80%;
    margin: auto;
  }

  .carriersTitle {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 1.5rem;
  }

  .projectLogo {
    width: 80%;
  }
}