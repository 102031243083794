.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.program {
  display: flex;
  gap: 50px;
  padding: 20px;
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
}

.info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
}

.title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.programs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.programButton {
  flex: 0 0 auto;
  align-self: center;
}

.checkbox {
  width:20px;
  height:20px;
  border: 2px solid #aaaaaa;
  border-radius: 50%;
  position: relative;
  flex: 0 0 auto;
}

.checkbox.checked::after {
  position: absolute;
  content: '';
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background-color: #0f62dd;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}