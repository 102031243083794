.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: min(1200px, 80vw);
  flex-grow: 1;
  gap: 25px;
  margin: auto;
}

.container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  background-color: #fafafa;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #eeeeee;
}

.icon {
  width: min(40vw, 150px);
  height: min(40vw, 150px);
  fill: #bbbbbb;
}

.container h1 {
  font-size: 2.5rem;
  font-weight: normal;
  color: #bbbbbb;
  margin-bottom: 0;
  text-align: center;
}

.container h2 {
  font-size: 2rem;
  color: #777777;
  font-weight: normal;
  margin-bottom: 0;
  text-align: center;
}

.container p {
  font-size: 1.5rem;
  color: #777777;
  font-weight: normal;
  text-align: center;
}

.container button {
  font-size: 1.5rem;
}

.container button a {
  color: #ffffff;
  text-decoration: none;
}