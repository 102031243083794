.tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.tab {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid #eeeeee;
  cursor: pointer;
}

.tab.selected {
  border-bottom: 3px solid #52CF52;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
}

.content {
  width: 100%;
  display: flex;
  flex-grow: 1;
}