.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container h2 {
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
}

.actions {
  display: flex;
  flex-shrink: 0;
  gap: 20px;
}

.appTItle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
}

.warning {
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: none;
}

.warning svg {
  width: 24px;
  height: 24px;
}

.noPrograms {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.agreement {
  font-size: medium;
  font-style: italic;
}

@media (max-width: 1000px) {
  .appTItle {
    flex-direction: column;
    gap: 10px;
    border: 0;
  }

  .actions {
    flex-direction: column;
    flex-shrink: unset;
  }
}
