.wholePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  
  width: min(900px, 90vw);
  box-sizing: border-box;
  
  padding: 40px;
  gap: 40px;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px -1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.title {
  font-size: 1.5em;
  font-weight: 500;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginInstead {
  display: flex;
}

.alreadyRegistered {
  margin-right: 1ch;
}

/**********************************************************/

.recaptcha {
  margin-top: 30px !important;
}

@media only screen and (max-width: 400px) {
  .recaptcha {
    margin: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }

  .loginInstead {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .alreadyRegistered {
    text-align: center;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .registerButton {
    width: 100%;
  }
}
