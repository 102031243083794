.toastMessage {
  width: 100%;
  padding: 20px;
  padding-left: 25px;
  border-left: 5px solid black;

  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.leftPart {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 20px;
}

.icon,
.close {
  width: 35px;
  height: 35px;
  fill: #777777;
  flex-shrink: 0;
}

.close {
  cursor: pointer;
}

.close:hover {
  background-color: rgba(175, 175, 175, 0.25);
  border-radius: 5px;
}

.toastMessages {
  position: sticky;
  bottom: 10vh;
  left: 50vw;
  transform: translateX(-50%);
  width: min(1000px, 80%);
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  z-index: 302;
}

.toastMessage.success {
  background-color: #eaf7ee;
  border-left-color: #3dbe61;
}
.toastMessage.success .icon {
  fill: #3dbe61;
}

.toastMessage.failure {
  background-color: #fcede9;
  border-left-color: #eb0000;
}
.toastMessage.failure .icon {
  fill: #eb0000;
}

.toastMessage.warning {
  background-color: #fef7ea;
  border-left-color: #ffc107;
}
.toastMessage.warning .icon {
  fill: #ffc107;
}

.toastMessage.info {
  background-color: #e6f0f8;
  border-left-color: #076de3;
}
.toastMessage.info .icon {
  fill: #076de3;
}

@media (max-width: 1000px) {
  .toastMessages {
    width: 95vw;
    font-size: 0.85rem;
  }

  .close {
    display: none;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .toastMessage {
    padding: 10px;
  }
}
