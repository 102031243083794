body {
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.whole-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  font-family: Roboto, sans-serif;
}

/*********************** input and buttons ************************/

button.defaultButton {
  background-color: #0D6EFD;
  color: #ffffff;
  border-radius: 5px !important;
  cursor: pointer;

  min-width: 120px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-size: 1rem;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;

  flex-shrink: 0;
}

.defaultButton > * {
  flex-shrink: 0;
}

button.defaultButton:hover {
  background-color: #0f62dd;
}

button.cancelButton {
  background-color: #ffffff;
  color: #0D6EFD;
  border-radius: 5px !important;
  cursor: pointer;

  min-width: 120px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  box-sizing: border-box;
  font-size: 1rem;
}

button.cancelButton:hover {
  background-color: #f1f1f1 !important;
}

.link {
  color: #0D6EFD;
  font-weight: bold;
  cursor: pointer;
  text-decoration:none;
  font-size: 1em;
  display: flex;
  gap: 5px;
  align-items: center;
}

.link:hover {
  color: #0f62dd;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #505050;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
  box-sizing: content-box;
  border-radius: 5px;
  overflow: hidden;
}

.pdf { fill: #E2574C; }
.png { fill: #577CFF; }
.zip { fill: #E7AE00; }
.defaultFile { fill: #295392; }
.doc { fill: #71AAC9; }