.managerNameOwnContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.managerNameOwn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
  padding: 5px 10px;

  border-radius: 5px;
  border: 1px solid #d2d6dc;
  box-sizing: border-box;
  background-color: #ffffff;

  cursor: pointer;
}

.managerNameX {
  fill: #96a6b5;
  width: 24px;
  height: 24px;
}

.managerNameOwn:hover {
  background-color: #f1f1f1;
}

.managerNameOwn:hover .managerNameX {
  fill: #777777;
}

.managerContainer {
  height: 36px;
  display: flex;
  align-items: center;
}
