.progress {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.circle {
  width: 4em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
}

.stage {
  font-size: 2em;
}

.circle.incomplete{
  border: 4px solid #DDDDDD;
  color: #6C6C6C;
}

.circle.success {
  background-color: #4CB71A;
  color: #ffffff;
}

.circle.failure {
  background-color: #DD403A;
  color: #ffffff;
}

.circle.current {
  border: 4px solid #0D6EFD;
  background-color: #ffffff;
  color: #0D6EFD;
}

.circle.current .text {
  color: #0D6EFD;
}

.check {
  width: 1.5em;
  height: 1.5em;
  fill: white;
}

.circle .text {
  position: absolute;
  color: #444444;
  width: fit-content;
  text-align: center;
  top: -20px;
  transform: translateY(-100%);
  font-size: 1.25em;
}

.line {
  background-color: #EEEEEE;
  display: flex;
  height: 6px;
  width: 100%;
  border-radius: 3px;
}

.circle.success + .line {
  background-color: #4CB71A;
}

.flag {
  width: 1.5em;
  height: 1.5em;
  fill: #6C6C6C;
}

.circle.current .flag {
  fill: #0D6EFD;
}

.circle .fail {
  width: 2.5em;
  height: 2.5em;
  fill: #ffffff;
}