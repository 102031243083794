.container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border-radius: 50%;

  box-sizing: border-box;
  animation: spin 2s linear infinite;
  
  /* circle size */
  width: 1.25rem;
  height: 1.25rem;

  /* spinning part thickness*/
  border: 4px solid #eeeeee;
  border-top: 4px solid #599bff;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}