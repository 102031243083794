.container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  flex-grow: 1;
}

.appsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  flex-grow: 1;
  margin: 20px 0px;
}

.grid {
  width: 100%;
}

.openButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.openButton {
  width: 24px;
  height: 24px;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  color: #0d6efd;
}

.openButton:hover {
  color: #0f62dd;
}

.noApplications {
  margin-top: 50px;
  color: #777777;
  margin-left: auto;
  margin-right: auto;
}