.infoContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  gap: 25px;
  max-width: 1200px;
}

.infoBullet {
  display: flex;
}

.infoBullets {
  display: flex;
  flex-direction: column;
  border-left: 3px solid #eeeeee;
  padding-left: 20px;
  gap: 8px;
}

.infoBullet .bullet {
  fill: #003476;
  height: 15px;
  width: 15px;
  margin-top: 5px;
  flex-shrink: 0;
  margin-right: 20px;
  opacity: 80%;
}

.infoBullet .info {
  font-size: 1em;
}

.underlinedInfo {
  font-size: 1.125em;
  text-decoration: underline;
  margin-top: 10px;
  margin-bottom: 10px;
}

.infoSegment {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.infoSegment .title {
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 10px;
}
