.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.imageContainer > img {
  width: 100%;
}

.info {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 1.25em;
  text-align: center;
  margin-bottom: 15px;
}

.desc {
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 3rem;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info > a > button {
  width: 100%;
}
