.csv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #107c41;
  align-self: center;
  color: white;
  text-decoration: none;
  gap: 10px;

  border: 0;
  margin: 0;
  height: 100%;

  cursor: pointer;
}

.csv:hover {
  background-color: #0d6334;
}

.csv svg {
  fill: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
}
