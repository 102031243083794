.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;

    margin: auto;
    transform: translateY(-50%);
    border-top: 1px solid #eeeeee;
}

.message {
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    color: #444444;
}

.icon {
    fill: #3DBE61;
    width: 8rem;
    height: 8rem;
}
