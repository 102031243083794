.titleContainer {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 40px;
}

.titleContainer.viewProgram {
  display: flex;
  align-items: baseline;
  gap: 30px;
}

.segmentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  box-sizing: border-box;
  width: 100%;

  border: 1px solid #eeeeee;
  padding: 40px;
  border-radius: 5px;
}

.segmentContainer.programForm {
  min-height: 500px;
}

.editable {
  border-left: 2px solid #52cf52  !important;
}

.segmentContainer + .segmentContainer {
  margin-top: 40px;
}

.announcementContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  background-color: #fafafa;
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}

.segmentActions {
  display: flex;
  gap: 10px;
}

.announcementActionsBottom {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

.announcementActionsTop {
  display: flex;
  gap: 20px;
  margin-left: auto;
}

.announcementActionsTop svg {
  width: 20px;
  height: 20px;
  fill: #999999;
  cursor: pointer;
}

.announcementActionsTop svg:hover {
  fill: #777777;
}

.modalAnnouncement {
  padding: 50px 30px;
  width: 700px;
  box-sizing: border-box;
}

.modalContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: min(700px, 90vw);
}

.actions {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
}

.edit {
  background-color: #52cf52 !important;
}
.edit:hover {
  background-color: #34bc34 !important;
}

.delete {
  background-color: #ff5252 !important;
}
.delete:hover {
  background-color: #eb0000 !important;
}