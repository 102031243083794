.container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

.grid {
  width: 100%;
}

.openButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.openButton {
  width: 24px;
  height: 24px;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  color: #0d6efd;
}

.openButton:hover {
  color: #0f62dd;
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.statusContainer > svg {
  width: 20px;
  height: 20px;
}
.statusContainer .failure {
  fill: #ff5252;
}
.statusContainer .success {
  fill: #3ebc5f;
}
.statusContainer .pending {
  fill: #ffc107;
}
