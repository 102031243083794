.container {
    display: flex;
    flex-grow: 1;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}