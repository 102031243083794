.form {
  overflow-y: visible;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 30px;
  color: #202124;
  align-items: flex-start;
  
  width: 100%;
  box-sizing: border-box;
}

.label-input {
  width: 100%;
}

.subform-title, .label-title {
  font-size: 1.5rem;
  width: 100%;

  padding-bottom: 5px;  
  border-bottom: 1px solid #eeeeee;
  display: flex;
}

.form-readOnly .subform-title, .form-readOnly .label-title {
  margin-bottom: 20px;
}

.form-general-input{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-item-input {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #B4B4B4;
  border-radius: 3px;

  font-size: 1rem;
  display: block;
  width: 100%;
  transition: border-color .35s ease-in-out,box-shadow .35s ease-in-out;
  outline: 0;
}

.form-item-input::placeholder{
  font-weight: normal;
}

.form-item-input:not(:read-only):focus {
  outline: 2px solid #0D6EFD;
  outline-offset: -2px;
}

.form-item-input:focus::placeholder{
  color: #0D6EFD;
}

.form-item-input-title {
  width: 100%;
}

.form-item-input-title, .form-item-input-title-error {
  font-size: 1.125em;
}

.form-item-input-error {
  width: 100%;
  background-color: #FCEDE9;
  border-left: 3px solid #EB0000;
  padding: 8px 10px;
  border-radius: 3px;
  box-sizing: border-box;
}

.form-item-input-check {
  border-radius: 0.25rem;
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.25rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  -webkit-print-color-adjust: exact;
}

.form-item-input-check:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-item-input-check[type=radio] {
  border-radius: 1em;
}

.form-item-input-check:checked {
  background-color: #0D6EFD;
  border-color: #0D6EFD;
}

.form-item-input-text {
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
}

.form-item-input-text-container {
  position: relative;
}

.form-item-input-password {
  padding: 10px calc(10px * 2 + 16px) 10px 10px;
}

.form-show-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(10px + 1px); /* padding-right + 1px (border) */
  fill: #777777;
  cursor: pointer;
}

textarea.form-item-input {
  min-height: 100px;
  resize: none;
}

textarea.readOnly-value-wrap {
  outline: 0;
  border: 0;
  font-size: 1rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

.react-datepicker__input-container .readOnly-value-wrap {
  width: 100%;
}

.form-date-input-popper .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__portal .form-date-input-calendar {
  display: flex;
}

.react-datepicker__portal .form-date-input-day {
  font-size: 1rem;
}

.react-datepicker__portal .form-date-time {
  font-size: 1rem !important;
  padding: 10px 20px !important;
  height: auto !important;
}

.react-datepicker__portal .form-date-input-calendar .react-datepicker__time-container {
  width: 150px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.react-datepicker__portal .form-date-input-calendar .react-datepicker__time {
  display: flex;
  flex-grow: 1;
}

.react-datepicker__portal .form-date-input-calendar .react-datepicker__time-box {
  width: 150px;
  display: flex;
  flex-grow: 1;
  overflow-y: scroll;
}

.react-datepicker__portal .form-date-input-calendar .react-datepicker__time-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
  overflow-y: initial !important;
}

.react-datepicker__portal .react-datepicker__navigation--next--with-time {
  right: 152px;
}

.react-datepicker__header__dropdown {
  padding-top: 10px;
  padding-bottom: 10px;
}

.react-datepicker__navigation.react-datepicker__navigation--years::after {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  width: 9px;
}

.react-datepicker__year-option:last-child {
  border-top: 1px solid #aeaeae;
  margin-top: 10px;
}

.react-datepicker__year-option:last-child:hover {
  background-color:transparent;
}

.react-datepicker__year-option:first-child:hover {
  background-color:transparent;
}

.react-datepicker__year-option:first-child {
  border-bottom: 1px solid #aeaeae;
  margin-bottom: 10px;
}

.react-datepicker__navigation--years-upcoming::after {
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-43%, 0px) rotate(-45deg);
}

.react-datepicker__navigation--years-previous::after {
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-43%, -100%) rotate(135deg);
}

.react-datepicker__year-option:first-child:hover .react-datepicker__navigation--years-upcoming:after  {
  border-color: #a6a6a6;
}

.react-datepicker__year-option:last-child:hover .react-datepicker__navigation--years-previous:after  {
  border-color: #a6a6a6;
}

.form-general-input:empty {
  display: none !important;
}

.form-readOnly {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  color: #202124;
}

.form-general-input-readOnly {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 100px;
}

.form-general-input-readOnly > *:not(.form-readOnly):not(.form-item-input-title):not(.form-array-input):not(.array-input) {
  flex-grow: 1;
  flex-basis: 0;
}

.form-input:not(:first-child).form-general-input-readOnly .subform-title {
  margin-top: 20px;
}

.label-title {
  margin-top: 20px;
}

.checkbox-input:not(.form-general-input-readOnly) {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.readOnly-value-wrap {
  border-radius: 5px;
  background-color: var(--main-theme);
  color: var(--main-theme-text);
  /* border: 1px solid var(--main-theme-text); */
  min-width: 16px;
  min-height: 16px;
  width: 50%;
  overflow-wrap: break-word;
}

.readOnly-value-wrap-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  background-color: #0D6EFD;
  margin-right: calc(50% - 16px);
  width: 16px;
  height: 16px;
  align-self: center;
  margin-top: 3px;
}

.readOnly-value-wrap-unchecked {
  background-color: #ffffff;
  border: 1px solid rgba(0,0,0,.25);
  margin-right: calc(50% - 16px);
  width: 16px;
  height: 16px;
  align-self: center;
  margin-top: 4px;
}

.form-label {
  height: auto;
}

/**************************** dropdown ****************************/

.MyDropdown__value-container {
  padding: 10px !important;
}

.MyDropdown__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

.MyDropdown__control{
  border-color: #B4B4B4 !important;
  border-radius: 3px !important;
}

.MyDropdown__control--is-focused {
  outline: 2px solid #0D6EFD !important;
  outline-offset: -2px !important;
}

.MyDropdown__option--is-focused {
  background-color: rgba(13,110,253,0.15) !important;
}

/**************************** array-input ****************************/

.array-input {
  width: 100%;
}

.array-input-element-container + .array-input-element-container {
  margin-top: 2em;
}

.form-general-input-readOnly .array-input-element-container + .array-input-element-container {
  margin-top: 8px;
}

.array-input-element-container + button{
  margin-top: 1em;
}

.array-input-element-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
  padding: 0;
  background-color: #f5f5f5;
  position: relative;
  overflow: visible;

  padding: 15px;
  margin-left: -15px;
  border-radius: 5px;
  width: calc(100% + 30px);
}

.array-input .push-form-button {
  border-radius: 5px !important;
  cursor: pointer;

  min-width: 120px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  border: 0;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  font-size: 1rem;
}

.array-input .btn-success {
  background-color: #4CAF50;
  color: #ffffff;
}

.array-input .btn-success:hover{
  background-color: #449C48;
}

.array-input .btn-danger {
  background-color: #FF5252;
  color: #ffffff;
  display: inline-flex;
}

.array-input .btn-danger:hover{
  background-color: #FF3838;
}

.array-input-x {
  width: fit-content;
  margin-left: auto;
}

.array-input-x button {
  border: 0;
  background-color: transparent;
  color: #777777;
  font: normal 1em/1 Arial, sans-serif;
  padding: 5px;
  cursor: pointer;
}

.array-input-x button:hover {
  color: #444444;
}

.MyDropdown__value-container--is-multi {
  padding: 7px !important;
}

.MyDropdown__multi-value {
  margin: 0 !important;
  margin-bottom: 2px !important;
  margin-right: 2px !important;
}

.MyDropdown__value-container--is-multi .MyDropdown__placeholder {
  margin-bottom: 2px !important;
  padding: 2px;
}

.MyDropdown__multi-value__label {
  font-size: 1rem !important;
  padding: 2px !important;
  padding-left: 6px !important;
  white-space: normal !important;
}

.MyDropdown__menu {
  position: absolute !important;
  top: 100% !important;
  z-index: 1 !important;
}

.form .drop-container {
  width: 100%;
  min-height: 64px;
  box-sizing: border-box;
  background-color: #ffffff;

  display: flex;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  border: 2px dashed #B4B4B4;
  box-sizing: border-box;
  user-select: none;
}

.form .drag-over {
  background-color: #EEF5FF;
  border: 2px dashed #0D6EFD;
}

.form .drop-message input {
  display: none;
}

.form .drop-message label{
  font-size: 1em;
}

.form .drop-message {
  display: flex;
  font-size: 1.25em;
  gap: 5px;
}

.form .pdf, .form .png, .form .zip, .form .defaultFile, .form .doc
{
  width: 24px;
  height: 24px;
}

.form-readOnly .pdf, .form-readOnly .png, .form-readOnly .zip, .form-readOnly .defaultFile, .form-readOnly .doc
{
  width: 24px;
  height: 24px;
}

.form .pdf, .form-readOnly .pdf { fill: #E2574C; }
.form .png, .form-readOnly .png { fill: #577CFF; }
.form .zip, .form-readOnly .zip { fill: #E7AE00; }
.form .defaultFile, .form-readOnly .defaultFile { fill: #295392; }
.form .doc, .form-readOnly .doc { fill: #71AAC9; }

.form .files, .form-readOnly .files {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  gap: 10px;
  border-radius: 5px;
  user-select: none;
}

.form .file {
  display: flex;
  align-items: center;
  width: min(100%, 500px);
  gap: 20px;
  min-height: 64px;

  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  padding: 15px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
}

.form-readOnly .file {
  width: min(100%, 500px);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.form .file-x {
  font: normal 1em/1 Arial, sans-serif;
  width: fit-content;
  margin-left: auto;
  padding: 5px;
  cursor: pointer;
  color: #777777;
}

.form .file-x:hover {
  color: #444444;
}

.form input:disabled {
  background: #eeeeee;
}

.form-item-input.disabled {
  background: #eeeeee;
}

@media (max-width: 1000px) {
  .form-general-input {
    flex-basis: 100% !important;
  }

  .form-general-input-readOnly {
    flex-direction: column;
    gap: 5px;
  }

  .form-general-input-readOnly label {
    font-weight: bold;
  }

  .form-general-input-readOnly > *:not(.form-readOnly):not(.form-item-input-title):not(.form-array-input):not(.array-input) {
    flex-grow: 1;
    flex-basis: auto;
  }

  .readOnly-value-wrap {
    width: auto;
  }

  .form-readOnly {
    gap: 20px;
  }

  .form .drop-message div {
    display: none;
  }
}