.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  display: flex;
  gap: 40px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  flex-shrink: 0;
}

.createApplicationWrapper {
  border: 1px solid #e5e7eb;
  border-top: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 20px;
}

.createApplicationWrapper button {
  margin: auto;
}

.openButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.openButton {
  width: 24px;
  height: 24px;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  color: #0d6efd;
}

.appContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.noPrograms {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.warning {
  padding: 10px;
  box-shadow: none;
}

.warning svg {
  width: 24px;
  height: 24px;
}

.evaluationMessage {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.evaluationComment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #444444;
}

.edit {
  background-color: #52cf52 !important;
}
.edit:hover {
  background-color: #34bc34 !important;
}

.card {
  display: flex;
  border: 1px solid #dedede;
  border-radius: 5px;
  flex-direction: row;
  padding: 20px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  gap: 20px;
}

.cardEdit {
  padding: 5px;
  align-self: center;
  margin-left: auto;
}

.cardTitle {
  font-size: 1rem;
  align-self: center;
}

.businessInfoExpandable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.businessInfoExpandable svg {
  fill: #777777;
}

.businessInfoExpandableContent {
  margin-top: 10px;
  padding: 0px 25px;
  background-color: #eeeeee;
  border-radius: 5px;
}

.businessInfoWarning {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .title {
    flex-direction: column;
    gap: 10px;
    border: 0;
  }

  .actions {
    flex-direction: column;
    flex-shrink: unset;
    margin: auto;
  }
}

.applicationCard {
  display: flex;
  flex-direction: column;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  gap: 5px;
  cursor: pointer;
}

.status {
  color: #777777;
}

.kayas {
  color: #003476;
}

.applicationCards {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.agreement {
  font-size: medium;
  font-style: italic;
}

@media (max-width: 400px) {
  .card {
    flex-direction: column;
    align-items: center;
  }

  .cardTitle {
    text-align: center;
  }

  .cardEdit {
    margin: 0;
  }
}
