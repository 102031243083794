.titleContainer {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 40px;
}

.titleContainer.allPrograms {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleContainer.createProgram {
  display: flex;
  align-items: baseline;
  gap: 30px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  box-sizing: border-box;
  flex-grow: 1;
}

.programTitle {
  font-size: 1.375rem;
  width: 100%;
  padding: 5px 0px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}

.programTabContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
}