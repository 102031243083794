.header {
  position: relative;
  display: flex;
  justify-content: stretch;
  gap: 10px;
  margin-bottom: 10px;
}

.tr:hover {
  background-color: #DEEBFF;
}

.th:first-child{
  border-top-left-radius: 8px;
  padding-left: 1px;
}

.th:last-child {
  border-top-right-radius: 8px;
  background-color: #77A8F1;
  box-shadow: unset;
  padding-right: 0px;
}

.th:last-child:hover {
  background-color: #0D6EFD;
}

.th {
  box-sizing: border-box;
  box-shadow: inset 0px 1px 0px #e5e7eb, inset 1px 0px 0px #e5e7eb, inset -1px 0px 0px #e5e7eb;
  padding-bottom: 0px;
  padding-left: 0px;
}

.th:hover {
  background-color: #e0e2e4;
  color: #5a606b;
}

.td {
  border: 1px solid #e5e7eb;
  font-size: 1rem;
  padding: 5px 10px;
}

tbody tr:nth-child(even) {
  background: #fbfbfb;
}

tbody tr:nth-child(odd) {
  background: #ffffff;
}

.table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
}

.headerFieldContainer{
  display: flex;
  flex-direction: column;
}

.headerField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;

  background-color: #f9fafb;
  color: #6B7280;
  font-size: 1.25rem;
  
  cursor: pointer;
}

.sortButton {
  display: none;
  position: absolute;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-collapse: collapse;
}

.footer {
  padding: 10px 20px;
  padding-top: 20px;
  border: 1px solid #e5e7eb;
  border-top: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.125);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.paginationSummary {
  color: #6B7280;
}

.paginationButton {
  font-size: 1rrem;
  padding: 5px 10px;
  background-color: #ffffff;
  outline: none;
  border: 1px solid #d2d6dc;
  border-right: 0;
  color: #3D4044;
}

.prev {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.next {
  border-right: 1px solid #d2d6dc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.paginationButtonCurrent {
  background-color: #f7f7f7;
  font-weight: bolder;
}

.paginationButton:not(.paginationButtonCurrent) {
  cursor: pointer;
}

.paginationButton:not(.paginationButtonCurrent):hover {
  background-color: #f7f7f7;
}

.notFound {
  text-align: center;
  padding: 20px;
}

/*****************************************************************************/

.search {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.searchIcon{
  width: 1rem;
  height: 1rem;
  left: 1rem;
  position: absolute;
  fill: #a9a9a9;
  filter: drop-shadow(1px 0px 0px #a9a9a9)
          drop-shadow(0px 1px 0px #a9a9a9);
}

.search input{
  width: 100%;
  padding: 10px 3rem;
  font-size: 1rem;
  
  border: 1px solid #e5e7eb;
  border-radius: 5px;
}

.search input:focus{
  outline: 2px solid #0D6EFD;
  outline-offset: -2px;
}

.search input:focus + .searchIcon {
  fill: #0D6EFD;
  filter: drop-shadow(1px 0px 0px #0D6EFD)
          drop-shadow(0px 1px 0px #0D6EFD);
}

/*****************************************************************************/

.filterButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  height: 27px;
}

.filterButton {
  width: 14px;
  height: 14px;
  fill: #ffffff;
}

.filterInputContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterInput {
  position: absolute;
  font-size: 1rem;

  width: calc(100%);
  height: 100%;
  padding-left: 3rem;
  padding-right: 20px;

  margin: 0;
  border: 0;
  border-top: 1px solid #e5e7eb;
  box-sizing: border-box;
}

.filterInputSearch{
  width: 1rem;
  height: 1rem;
  left: 1rem;
  position: absolute;
  fill: #a9a9a9;
  filter: drop-shadow(1px 0px 0px #a9a9a9)
          drop-shadow(0px 1px 0px #a9a9a9);
  top: 50%;
  transform: translateY(-50%);
}

.filterInput:focus + .filterInputSearch {
  fill: #0D6EFD;
  filter: drop-shadow(1px 0px 0px #0D6EFD)
          drop-shadow(0px 1px 0px #0D6EFD)
}

.filterInput:focus {
  outline: 2px solid #0D6EFD;
  outline-offset: -2px;
}

.filterSelectionContainer{
  position: relative;
}