.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.appsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  flex-grow: 1;
  margin: 20px 0px;
}

.grid {
  width: 100%;
}

.openButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.openButton {
  width: 24px;
  height: 24px;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  color: #0d6efd;
}

.openButton:hover {
  color: #0f62dd;
}

.appGroupTitle {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.xButton {
  width: 24px;
  height: 24px;
  fill: #777777;
  padding: 5px;
  flex-shrink: 0;
}

.xButton:hover {
  background-color: #eeeeee;
}

.noApplications {
  margin-top: 50px;
  color: #777777;
  margin-left: auto;
  margin-right: auto;
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.statusContainer > svg {
  width: 20px;
  height: 20px;
}
.statusContainer .failure {
  fill: #ff5252;
}
.statusContainer .success {
  fill: #3ebc5f;
}
.statusContainer .pending {
  fill: #ffc107;
}
